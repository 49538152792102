.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}
body {
  font-family: 'Manrope', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Ubuntu, sans-serif !important;
}
.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(0px);
  }
}

.icon-prefix {
  position: absolute;
  z-index: 1000;
  top: 12px;
  left: 10px;
}

.icon-sufix {
  position: absolute;
  z-index: 1000;
  top: 12px;
  right: 10px;
}

.bg-app {
  background-color: #ede9fc !important;
}

.border-dash {
  border: 1px dashed lightgray;
}

.sized70 {
  font-size: 12px !important;
}

.sized50 {
  font-size: 0.5rem !important;
}

.form-control {
  border: 1px solid #8894aa !important;
  border-radius: 0.5rem !important;
  box-shadow: none !important;
  font-size: 16px !important;
}

.form-select {
  border: 1px solid #8894aa !important;
  border-radius: 0.5rem !important;
}

.btn-link {
  text-decoration: none !important;
  font-weight: bold !important;
  color: #4d88f0 !important;
}

.btn-primary {
  color: #fff !important;
}

.btn-link {
  color: #172640 !important;
}

.btn-primary:hover {
  background-color: #172640 !important;
  border: 1px solid #172640 !important;
}

select {
  height: 48px;
}

input {
  height: 48px;
  color: #0c2551 !important;
}

.form-check-input:checked {
  background-color: #4d88f0 !important;
  border-color: #4d88f0 !important;
}

.pointer {
  cursor: pointer;
}

.main-container {
  padding-top: 64px !important;
  padding-left: 24px !important;
  margin-bottom: 24px !important;
}

.row {
  padding: 0px !important;
  margin: 0px !important;
}

.row>* {
  padding: 0px !important;
  margin: 0px !important;
}

.row>p {
  padding: 0.25rem !important;
}

select:disabled {
  opacity: 0.5 !important;
}

.form-control:disabled {
  opacity: 0.5 !important;
}

.rich-border {
  border-top: 8px #dfe2e6 solid;
}

.fs-7 {
  font-size: 12px !important;
}

span .MuiFormControlLabel-label {
  font-size: 14px !important;
  font-weight: 500 !important;
}

span .Mui-checked .MuiRadio-colorPrimary {
  color: #4d88f0 !important;
}

.cm-logo {
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
}

.cm-logo::after {
  display: block;
  padding-left: 4px;
  padding-top: 2px;
  content: url(/src/assets/icon-cm.svg);
}

.uc-logo::after {
  display: block;
  content: url(/src/assets/icon-uc.svg);
  padding-left: 4px;
  padding-top: 2px;
}

.cm-uc-logo::after {
  display: block;
  content: url(/src/assets/icon-cm-uc.svg);
  padding-left: 4px;
  padding-top: 2px;
}

.span-uc-logo {
  display: inline-block;
  position: relative;
}

.span-uc-logo,
.span-cm-logo,
.span-cm-uc-logo,
.span-csu-logo,
.span-uc-csu-logo {
  display: inline-block;
  margin-top: 2px;
}

.span-uc-logo::after,
.span-cm-logo::after,
.span-cm-uc-logo::after,
.span-csu-logo::after,
.span-uc-csu-logo::after {
  display: inline-block;
  width: 0px;
  padding-left: 6px;
  margin-top: -8px;
  transform: translate(0, 4px);
}

.span-csu-logo2 ::after {
  position: relative;
  bottom: 0;
  padding-left: 8px;
}

.span-uc-logo::after {
  content: url(/src/assets/icon-uc.svg);
}

.span-cm-logo::after {
  content: url(/src/assets/icon-cm.svg);
}

.span-cm-uc-logo::after {
  content: url(/src/assets/icon-cm-uc.svg);
}

.span-csu-logo2::after,
.span-csu-logo::after {
  content: url(/src/assets/icon-csu-5.svg);
}

.span-uc-csu-logo::after {
  content: url(/src/assets/icon-uc-csu.svg);
}

.mh-1rem {
  min-height: 1.25rem;
}

pre.summary {
  font-size: .95em;
  font-weight: 500;
  font-family: "Manrope",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Ubuntu,sans-serif!important;
  line-height: 19.5px;
}

/* .css-1uuuw5a-MuiInputBase-root-MuiOutlinedInput-root{
  padding-top: 0px !important;
}

.css-17mpy8z {
  padding-top: 0px !important;
} */

.MuiSelect-root {
  padding-top: 0px !important;
}

.MuiAutocomplete-root {
  /* padding: 0px !important; */
  margin-top: 4px !important;
  padding-top: 0px !important;
}

.MuiOutlinedInput-root {
  padding-top: 0px !important;
}

.MuiPaper-root {
  font-size: 0.95rem !important;
}

.google-login-wrapper,
.google-login-wrapper > div,
.google-login-wrapper div[style] {
  width: 100% !important;
}

.google-login-wrapper iframe {
  width: 100% !important;
  margin: 0 !important;
  max-width: none !important;
}